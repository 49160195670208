import { Card, Col, Image, Row, Stack } from "react-bootstrap";
import comingSoonImage from "../../assets/images/coming-soon.svg";
import Loader from "../../../components/common/Loader";
import Documents from "./sections/Documents";
import { useState } from "react";
import Questionnaires from "./sections/Questionnaires";
import CaseDetails from "./sections/CaseDetails";

const DashboardCompany = () => {
  const [dashboardData, setDashboardData] = useState<any>("");

  return (
    <>
      <Loader isLoading={false} />
      <div className="d-flex flex-column pageContainer p-3 h-100 overflow-auto">
        <Row className="gx-3 py-2">
          {/* Documents Section */}
          <Col sm={6} className="mb-3 pb-1">
            <Documents dashboardData={dashboardData} />
          </Col>

          {/* Questionnaires Section */}
          <Col sm={6} className="mb-3 pb-1">
            <Questionnaires dashboardData={dashboardData} />
          </Col>

          {/* Case Details Section */}
          <Col xs={12} className="mb-3">
            <CaseDetails dashboardData={dashboardData} />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default DashboardCompany;
