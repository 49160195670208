import { ThunkDispatch } from "@reduxjs/toolkit";
import { getIn } from "formik";
import { FC } from "react";
import { Button, Col, Form, Modal, Row, Stack } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import CommonFormikComponent from "../../../../../../components/common/CommonFormikComponent";
import TextEditor from "../../../../../../components/common/Editor/TextEditor";
import { addNotesAction, editNotesAction } from "../../../../../../redux/companyNotesModule/notesSlice";
import { notesSchema } from "../../../../../../validations/companyNotes";
import { store } from "../../../../../../store";

interface IAddNotesModal {
  show?: boolean;
  handleClose?: any;
  notesData?: any;
}

/**
 * Add Notes Modal Component
 * @date 1/3/2024 - 3:42:20 PM
 *
 * @param {{ show: any; handleClose: any; }} {
  show,
  handleClose,
}
 * @returns {*}
 */

const AddNotesModal: FC<IAddNotesModal> = ({
  show,
  handleClose,
  notesData,
}) => {
  const companyID = store?.getState()?.auth?.userInfo?.company_id
  const caseID = useSelector((state: any) => state.companyCaseSlice.caseID);
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  // Initial Values
  const initialValues: Record<string, any> = {
    note_content: notesData?.note_content ?? "",
  };

  const handleSubmit = (values: any, actions: any) => {
    let payload = {...values}
    if (!notesData) {
      payload = {...values, case_id: caseID, company_id: companyID};
      dispatch(addNotesAction(payload)).then((data: any) => {
        if (data.payload.statusCode == 200) {
          handleClose();
        }
      }).catch((err: any) => {
        console.log(err);
      });
    } else {
      payload.notes_id = notesData.notes_id;
      dispatch(editNotesAction(payload)).then((data: any) => {
        if (data.payload.statusCode == 200) {
          handleClose();
        }
      }).catch((err: any) => {
        console.log(err);
      });
    }
  }

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered={true}
      scrollable={true}
      size="lg"
      className="theme-modal"
      enforceFocus={false}
    >
      <Modal.Header closeButton>
        <Modal.Title as="h6" className="fw-semibold">
          {!notesData ? 'Create' : 'Update'} Note
        </Modal.Title>
      </Modal.Header>
      <CommonFormikComponent
        validationSchema={notesSchema}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {(formikProps) => (
          <>
            <Modal.Body className="pt-3 pb-2 min-h-100">
              <Row>
                <Col sm={12} className="mb-3">
                  <TextEditor
                    controlId="note_content"
                    data={formikProps.values.note_content}
                    handleChange={(value: any) => {
                      formikProps.setFieldValue("note_content", value.htmlValue);
                    }}
                    editorHeight="250px"
                  />
                  <Form.Control.Feedback
                    type="invalid"
                    className={
                      formikProps.errors.note_content &&
                        formikProps.touched.note_content
                        ? "d-block"
                        : "d-none"
                    }
                  >
                    {getIn(formikProps.errors, "note_content")}
                  </Form.Control.Feedback>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer className="py-2">
              <Stack direction="horizontal" gap={3} className="flex-wrap py-1">
                <Button
                  variant="outline-primary"
                  onClick={handleClose}
                  className="min-w-80"
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  className="min-w-80"
                >
                  {!notesData ? 'Submit' : 'Update'}
                </Button>
              </Stack>
            </Modal.Footer>
          </>
        )}
      </CommonFormikComponent>
    </Modal>
  );
};

export default AddNotesModal;
