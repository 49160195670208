import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { axiosRequest } from '../../store/common-api/axios';
import EndPoint from '../../api/endpoint';
const initialState = {
    caseID: "",
    activeTabForCases: "questionnaires",
    caseDetailsData: {},
    loading: false,
};


// GETTING ALL CASES OF COMPANY
export const getCompanyDropdownCasesAction = createAsyncThunk(
    "companyCasesModule/getCompanyDropdownCasesAction",
    async () => {
        try {
            const response = await axiosRequest.get(`${EndPoint.COMPANY_CASES_DROPDOWN_API}`);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);


// GETTING DETAILS OF SELECTED CASE
export const getCompanyCasesDetailsAction = createAsyncThunk(
    "companyCasesModule/getCompanyCasesDetailsAction",
    async (caseID: any) => {
        try {
            const response = await axiosRequest.get(`${EndPoint.COMPANY_CASES_DETAILS_API}/${caseID}`);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);

const companyCaseSlice = createSlice({
    name: 'Company Case Management',
    initialState: initialState,
    reducers: {
        startLoader(state, actions) {
            state.loading = true;
        },
        stopLoader(state, actions) {
            state.loading = false;
        },
        changeCompanyCasesID(state, action) {
            state.caseID = action.payload.caseID
        },
        changeCompanyCasesActiveTab(state, action) {
            state.activeTabForCases = action.payload.activeTabForCases
        }
    },
    extraReducers: (builder) => {
        builder
            // GETTING ALL CASES OF COMPANY
            .addCase(getCompanyDropdownCasesAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(getCompanyDropdownCasesAction.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(getCompanyDropdownCasesAction.rejected, (state, action) => {
                state.loading = false;
            })


            // GETTING DETAILS OF SELECTED CASE
            .addCase(getCompanyCasesDetailsAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(getCompanyCasesDetailsAction.fulfilled, (state, action) => {
                let data = action?.payload?.data;
                state.caseDetailsData = data;
                state.loading = false;

            })
            .addCase(getCompanyCasesDetailsAction.rejected, (state, action) => {
                state.loading = false;
            })

    }
});

export const companyCaseActions = companyCaseSlice.actions;

export default companyCaseSlice.reducer;