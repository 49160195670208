/*!
This File contain all route that will use in ./src/index.js & in navigations
*/

import appConfig from "../helpers/config";

const basePath = appConfig.basePath;

export const baseRoutes = {
  login: {
    path: `${basePath}/login`,
    pathName: "Back to Login",
  },
  setAccount: {
    path: `${basePath}/set-account`,
    pathName: "Set Account",
  },
  forgotPassword: {
    path: `${basePath}/forgot-password`,
    pathName: "Forgot Password",
  },
  resetPassword: {
    path: `${basePath}/reset-password`,
    pathName: "Reset Password",
  },
  dashboard: {
    path: `${basePath}/dashboard`,
    pathName: "Dashboard",
  },
  questionnaires: {
    path: `${basePath}/questionnaires`,
    pathName: "Questionnaires",
  },
  documents: {
    path: `${basePath}/documents`,
    pathName: "Documents",
  },
  notes: {
    path: `${basePath}/notes`,
    pathName: "Notes",
  },
  changePassword: {
    path: `${basePath}/change-password`,
    pathName: "Change Password",
  },
  cases: {
    path: `${basePath}/cases`,
    pathName: "Cases",
  },
};
