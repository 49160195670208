import {
  MdAssignmentInd,
  MdDashboard,
  MdLibraryBooks
} from "react-icons/md";
import appConfig from "../../../../helpers/config";
import { baseRoutes } from "../../../../routes/base-routes";
import { SvgIcons } from "../../SvgIcons";
const basePath = appConfig.basePath;
export const NavItemsClient = [
  {
    id: 1,
    menuName: "Dashboard",
    menuIcon: <MdDashboard size={21} />,
    path: `${basePath}/dashboard`,
    disabled: false, 
    hideInMobile: false,
  },
  {
    id: 2,
    menuName: "Questionnaires",
    menuIcon: SvgIcons.manageTeamIcon,
    path: baseRoutes.questionnaires.path,
    disabled: false, 
    hideInMobile: false,
  },
  {
    id: 3,
    menuName: "Documents",
    menuIcon: <MdAssignmentInd size={22} />,
    path: `${basePath}/documents`,
    disabled: false, 
    hideInMobile: false,
  },
  {
    id: 4,
    menuName: "Notes",
    menuIcon: <MdLibraryBooks size={22} />,
    path: `${basePath}/notes`,
    disabled: false, 
    hideInMobile: false,
  }
];


export const NavItemsCompany = [
  {
    id: 1,
    menuName: "Dashboard",
    menuIcon: <MdDashboard size={21} />,
    path: `${basePath}/dashboard`,
    disabled: true, 
    hideInMobile: false,
  },
  {
    id: 2,
    menuName: "Documents",
    menuIcon: <MdAssignmentInd size={22} />,
    path: `${basePath}/documents`,
    disabled: true, 
    hideInMobile: false,
  },
  {
    id: 3,
    menuName: "Cases",
    menuIcon: SvgIcons.caseManagementIcon,
    path: `${basePath}/cases`,
    disabled: false, 
    hideInMobile: false,
  }
];
