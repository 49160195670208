import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { axiosRequest } from '../../store/common-api/axios';
import EndPoint from '../../api/endpoint';
const initialState = {
    questionnaire: [],
    loading: false,
};

// GETTING ALL QUESTIONNAIRE LIST
export const getQuestionnairesListAction = createAsyncThunk(
    "companyQuestionnairesModule/getQuestionnairesListAction",
    async (value: any) => {
        const apiUrl = `${EndPoint.COMPANY_QUESTIONAIRRE_LIST}?search=${value.search}&case_id=${value.case_id}&petitioner_id=${value.petitioner_id}`;
        try {
            const response = await axiosRequest.get(apiUrl);

            return response;
        } catch (error: any) {
            return error;

        }
    }
);


const companyQuestionnaireSlice = createSlice({
    name: 'Company Questionnaire Management',
    initialState: initialState,
    reducers: {
        startLoader(state, actions) {
            state.loading = true;
        },
        stopLoader(state, actions) {
            state.loading = false;
        },
        // saveLastSection(state, action) {
        //     state.lastSavedSectionID = action.payload.lastSavedSectionID
        // }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getQuestionnairesListAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(getQuestionnairesListAction.fulfilled, (state, action) => {
                state.loading = false;
                let data = action.payload.data ? action.payload.data : []
                state.loading = false;

                let dataArray: any = []
                // if (data?.payload?.statusCode == 200) {
                    data?.forEach((el: any) => {
                        for (const key in el) {
                            const element = el[key];
                            dataArray.push({
                                id: Math.random(),
                                title: key.split(":")[0],
                                userName: key.split(":")[1],
                                content: element
                            })
                        }
                    });
                    
                    state.questionnaire = dataArray; // setting questionnaire data
                // }

            })
            .addCase(getQuestionnairesListAction.rejected, (state, action) => {
                state.loading = false;
            })
    }
});

export const companyQuestionairreActions = companyQuestionnaireSlice.actions;

export default companyQuestionnaireSlice.reducer;