import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import EndPoint from '../../api/endpoint';
import { DEF_SORT_ORDER, LIST_CRR_PAGE, PAGINATOR_ROWS_DEF, ROLES_DEFAULT_SORT_COLUMN } from '../../constants/global';
import { axiosRequest } from '../../store/common-api/axios';

const initialState = {
    notes: [],
    loading: false,
    listObj: {
        sortOrderProp: DEF_SORT_ORDER,
        currentPageProp: LIST_CRR_PAGE,
        rowsProp: PAGINATOR_ROWS_DEF,
        sortFieldProp: ROLES_DEFAULT_SORT_COLUMN
    }
};

// GETTING ALL NOTES LIST
export const getNotesListAction = createAsyncThunk(
    "notesModule/getNotesListAction",
    async (value: any) => {
        const apiUrl = `${EndPoint.NOTES_LIST}?page=${value.currentPage}&size=${value.rows}&sort=${value.sortField}&sort_order=${value.sortOrder}&case_id=${value.case_id}`;
        try {
            const response = await axiosRequest.get(apiUrl);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);

// ADD NOTES 
export const addNotesAction = createAsyncThunk(
    "notesModule/addNotesAction",
    async (value: any) => {
        try {
            const response = await axiosRequest.post(`${EndPoint.NOTES_ADD}`, value);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);

// EDIT NOTES 
export const editNotesAction = createAsyncThunk(
    "notesModule/editNotesAction",
    async (value: any) => {
        try {
            const response = await axiosRequest.put(`${EndPoint.NOTES_EDIT}`, value);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);

// VIEW NOTES
export const viewNotesAction = createAsyncThunk(
    "notesModule/viewNotesAction",
    async (value: any) => {
        const apiUrl = `${EndPoint.NOTES_VIEW}/${value.noteId}`
        try {
            const response = await axiosRequest.get(apiUrl);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);

export const deleteNotesAction = createAsyncThunk(
    "notesModule/deleteNotesAction",
    async (value: any) => {
        try {
            const response = await axiosRequest.delete(`${EndPoint.NOTES_DELETE}/${value.notes_id}`);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);

const companyNotesSlice = createSlice({
    name: 'Notes',
    initialState: initialState,
    reducers: {
        changeListDataObj(state, actions) {
            state.listObj.sortOrderProp = actions.payload.sortOrder;
            state.listObj.currentPageProp = actions.payload.currentPage;
            state.listObj.rowsProp = actions.payload.rows;
            state.listObj.sortFieldProp = actions.payload.sortField;
        },
        startLoader(state, actions) {
            state.loading = true;
        },
        stopLoader(state, actions) {
            state.loading = false;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getNotesListAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(getNotesListAction.fulfilled, (state, action) => {
                state.loading = false;
                let data = action.payload.data ? action.payload.data : []
                state.loading = false;
                state.notes = data;
            })
            .addCase(getNotesListAction.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(addNotesAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(addNotesAction.fulfilled, (state, action) => {
                state.loading = false
            })
            .addCase(addNotesAction.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(editNotesAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(editNotesAction.fulfilled, (state, action) => {
                state.loading = false
            })
            .addCase(editNotesAction.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(viewNotesAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(viewNotesAction.fulfilled, (state, action) => {
                state.loading = false
            })
            .addCase(viewNotesAction.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(deleteNotesAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteNotesAction.fulfilled, (state, action) => {
                state.loading = false
            })
            .addCase(deleteNotesAction.rejected, (state, action) => {
                state.loading = false;
            })
    }
});

export const companyNotesActions = companyNotesSlice.actions;

export default companyNotesSlice.reducer;