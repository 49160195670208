import { Accordion, AccordionTab } from 'primereact/accordion';
import { FC } from 'react';
import { Stack } from 'react-bootstrap';
import DataGridCommon from '../../../../components/common/dataGrid/DataGridCommon';
import { HIDE_TABLE_PAGINATOR } from '../../../../constants/global';
interface ICaseDetails {
    dashboardData?: any
}

const CaseDetails: FC<ICaseDetails> = ({
    dashboardData
}) => {

    const sNoCellTemplate = (rowData: any, index: any) => {
        return <span className="min-w-20 d-inline-block text-center">{index?.rowIndex + 1}</span>
    }

    // Table Columns
    const columns = [
        {
            field: "s_no",
            header: "No.",
            body: sNoCellTemplate,
            headerClassName: "text-wrap",
            width: '50px',
        },
        {
            field: "case_no",
            header: "Case No.",
        },
        {
            field: "employee_name",
            header: "Employee Name",
        },
        {
            field: "case_type",
            header: "Case Type",
        },
        {
            field: "billing",
            header: "Billing",
        },
        {
            field: "status",
            header: "Status",
        },
        {
            field: "documents",
            header: "Documents",
        },
        {
            field: "questionnaires",
            header: "Questionnaires",
        },
    ];

    return (
        <>
            <div className="theme-accordion-cover shadow-lg">
                <Accordion
                    multiple
                    activeIndex={[0]}
                >
                    <AccordionTab
                        headerTemplate={
                            <Stack
                                direction="horizontal"
                                gap={2}
                                className="flex-fill pe-3 mw-1"
                            >
                                <div className="d-inline-flex theme-accordion-title mw-1">
                                    <h5 className="text-primary fw-bold mb-0 mw-100 pe-1 text-truncate">Case Details</h5>
                                </div>
                            </Stack>
                        }
                    >
                        <div className="theme-accordion-data">
                            <DataGridCommon
                                columns={columns}
                                data={dashboardData?.CaseEventsRecord}
                                HideTablePaginator={HIDE_TABLE_PAGINATOR}
                            />
                        </div>
                    </AccordionTab>
                </Accordion>
            </div>
        </>
    )
}

export default CaseDetails