import { Route, Routes } from 'react-router-dom';
import { baseRoutes } from '../../../routes/base-routes';
import CasesList from './index';


export const Cases = () => {
    return (
        <Routes>
            {<Route path="/" element={<CasesList />} />}
        </Routes>
    )
};