import { ThunkDispatch } from '@reduxjs/toolkit'
import { useEffect, useState } from 'react'
import { Badge, Card, Col, Row, Stack } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import ThemeCustomDropdown from '../../../components/common/CustomDropdown'
import Loader from '../../../components/common/Loader'
import { SvgIcons } from '../../../components/common/SvgIcons'
import { companyCaseActions, getCompanyCasesDetailsAction, getCompanyDropdownCasesAction } from '../../../redux/companyCasesModule/caseSlice'
import CaseTabs from './tabs'

const CasesList = () => {
  const isLoading = useSelector((state: any) => state.companyCaseSlice.loading);
  const caseID = useSelector((state: any) => state.companyCaseSlice.caseID);
  const caseDetailsData = useSelector((state: any) => state.companyCaseSlice.caseDetailsData);
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const [caseOptions, setCaseOptions] = useState<any>(caseID ? caseID : '');
  const [caseOptionsData, setCaseOptionsData]: any = useState<any>([]);

  const billingStatusClassName = (rowData: any) => {
    let status: any = rowData;
    const successStatuses = ['Current', 'Stage II PIF Closed', 'Stage I PIF'];
    const dangerStatuses = ['Collections', 'Termination Stage', 'Cancelled', 'Stage II Cancelled', 'Offboard'];

    if (successStatuses?.includes(status)) {
      return 'bg-success-subtle text-success';
    } else if (status === 'Not Applicable') {
      return 'bg-dark-subtle';
    } else if (status === 'Default') {
      return 'bg-warning-subtle text-warning';
    } else if (dangerStatuses.includes(status)) {
      return 'bg-danger-subtle text-danger';
    } else {
      return '';
    }
  };

  useEffect(() => {
    getCompanyCasesDropdown();
  }, []);

  useEffect(() => {
    if(!caseOptions) return
    getCaseDetailsData()
  }, [caseOptions])

  const getCompanyCasesDropdown = () => {
    dispatch(getCompanyDropdownCasesAction()).then(data => {      
      if (data?.payload?.statusCode === 200) {
        let cases: any = []
        data?.payload?.data?.forEach((element: { file_number: any; id: any; }) => {
          cases.push({
            label: element.file_number, value: element.id
          });
        });
        setCaseOptionsData(cases);
        setCaseOptions(caseID ? caseID : cases[0]?.value);
        dispatch(companyCaseActions.changeCompanyCasesID({ caseID: caseID ? caseID : cases[0]?.value }));
      }
    })
  }

  const getCaseDetailsData = () => {
    dispatch(getCompanyCasesDetailsAction(caseOptions))
  }

  const handleCasesDropdown = (event: any) => {
    setCaseOptions(event.value);
    dispatch(companyCaseActions.changeCompanyCasesID({ caseID: event.value }));
  }

  return (
    <>
      <Loader isLoading={isLoading} />
      <div className="d-flex flex-column pageContainer p-3 h-100 overflow-y-scroll">
        <div className="contentHeader p-1">
          <Stack
            direction="horizontal"
            gap={2}
            className="flex-wrap mb-2"
          >
            <h1 className="fs-4 fw-semibold mb-0 me-auto">Cases</h1>
            <div className="w-220">
              <ThemeCustomDropdown
                controlId="case_id"
                placeHolder="Case#"
                data={caseOptionsData}
                value={caseOptions}
                handleFilter={handleCasesDropdown}
              />
            </div>
          </Stack>
        </div>

        <div className="flex-grow-1 pageContent position-relative pt-4 d-flex flex-column">
          <Card className="shadow-lg border-0 theme-card-cover">
            <div className="theme-card-header px-1">
              <Stack
                direction="horizontal"
                gap={3}
                className="flex-wrap px-3 pt-3 pb-2 align-items-start"
              >
                <h5 className="mb-0 position-relative fw-normal me-auto">
                  <div className="align-items-center bg-secondary d-inline-flex h-60 justify-content-center position-absolute rounded start-0 text-white theme-icon-box w-60 z-1">
                    <span className="theme-icon-box-inner text-center">
                      {SvgIcons.caseManagementIcon}
                    </span>
                  </div>
                  Case Details
                </h5>
              </Stack>
            </div>
            <div className="px-20 pt-3">
              <Row className='justify-content-between'>
                <Col sm className='mb-3'>
                  <h5 className="text-primary mb-0 fw-bold text-capitalize">
                  {caseDetailsData?.case_petitioner_info?.[0]?.c_pti_comid_info?.legal_name}
                  </h5>
                </Col>
                {
                caseDetailsData?.signing_authority_info?.full_name ?  
                  <Col sm={"auto"} className='mb-3'>
                    <h5 className="text-primary mb-0">
                      <span className='fw-bold text-nowrap'>Attorney: </span>
                      <span className="fw-normal text-capitalize">{caseDetailsData?.signing_authority_info?.full_name}</span>
                    </h5>
                  </Col> 
                : ""
                }
              </Row>
              <div className='border-top pt-3'>
                <Row className='justify-content-between'>
                  <Col md={4} className='mb-3'>
                    <div className="d-flex">
                      <span className="text-nowrap fw-semibold pe-1">Case Type: </span>
                      <span className="pe-2">{caseDetailsData?.case_type_info?.name}</span>
                      <span className='d-inline-flex my-auto'><Badge pill bg={caseDetailsData?.case_status !== 'Closed' ? 'success' : 'danger'}>{caseDetailsData?.case_status}</Badge></span>
                    </div>
                  </Col>
                  <Col sm={6} md={4} className='mb-3'>
                    <div className="d-flex">
                      <span className="text-nowrap fw-semibold pe-1">Case No: </span>
                      <span className="pe-1 me-auto">{caseDetailsData?.file_number}</span>
                    </div>
                  </Col>
                  <Col sm="auto" className='mb-3'>
                    <div className="d-flex">
                      <span className="text-nowrap fw-semibold pe-2">Billing Status: </span>
                      <span className='d-inline-flex my-auto'><Badge pill className={billingStatusClassName(caseDetailsData?.case_client_info?.billing_status)}>{caseDetailsData?.case_client_info?.billing_status}</Badge></span>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Card>
          <CaseTabs caseID={caseOptions} caseData={caseDetailsData}/>
        </div>
      </div>
    </>
  )
}

export default CasesList