import { ThunkDispatch } from '@reduxjs/toolkit'
import { FC, useEffect, useState } from 'react'
import { Button, Stack } from 'react-bootstrap'
import { MdAddCircleOutline } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import DeleteModal from '../../../../../components/common/DeleteModal'
import Loader from '../../../../../components/common/Loader'
import RenderAction from '../../../../../components/common/dataGrid/ActionCell'
import DataGridCommonNew from '../../../../../components/common/dataGrid/DataGridCommonCustom'
import { DEF_SORT_ORDER, DELETE_NOTE_CONTENT, DELETE_NOTE_TITLE, HIDE_TABLE_PAGINATOR, LIST_CRR_PAGE, PAGINATOR_ROWS_DEF, REACT_TABLE_ORDER, ROLES_DEFAULT_SORT_COLUMN } from '../../../../../constants/global'
import AddNotesModal from './modals/AddNotesModal'
import { getNotesListAction, deleteNotesAction, companyNotesActions, viewNotesAction } from '../../../../../redux/companyNotesModule/notesSlice'
import ViewNotesModal from './modals/ViewNotesModal'


interface INotesTabs {
    
}
const NotesTab: FC<INotesTabs> = ({
   
}) => {
    const caseID = useSelector((state: any) => state?.companyCaseSlice?.caseID);
    const caseDetailsData = useSelector((state: any) => state.companyCaseSlice.caseDetailsData);
    const isLoading = useSelector((state: any) => state.companyNotesSlice.loading);
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const [deleteShow, setDeleteShow] = useState(false);
    const [addNotesModalShow, setAddNotesModalShow] = useState(false);
    const [viewNotesModalShow, setViewNotesModalShow] = useState(false);
    const [notesInfo, setNotesInfo] = useState<any>(null);
    const [notesDelete, setNotesDelete] = useState({});
    const location = useLocation();
    const listObjData = useSelector((state: any) => state.companyNotesSlice.listObj);
    const [currentPage, setCurrentPage] = useState(listObjData ? listObjData?.currentPageProp : LIST_CRR_PAGE);
    const [sortOrder, setSortOrder] = useState(listObjData ? listObjData?.sortOrderProp : DEF_SORT_ORDER);
    const [sortField, setSortField] = useState(listObjData ? listObjData?.sortFieldProp : ROLES_DEFAULT_SORT_COLUMN);
    const [rows, setRows] = useState(listObjData ? listObjData?.rowsProp : PAGINATOR_ROWS_DEF);
    let locationObj = location.state !== undefined || location.state !== null ? location.state : undefined;
    const notesTypeList = useSelector((state: any) => state.companyNotesSlice.notes);
    const notesList = notesTypeList === null || notesTypeList === undefined ? [] : notesTypeList.records;
    
    useEffect(() => {
        if (!caseDetailsData && !caseID) return;
        let rolePayload: any = {};
        if (locationObj) {
            rolePayload = {
                sortOrder: listObjData.sortOrderProp,
                currentPage: listObjData.currentPageProp,
                rows: listObjData.rowsProp,
                sortField: listObjData.sortFieldProp,
                case_id: caseID,
            };
            setSortOrder(listObjData.sortOrderProp);
            setCurrentPage(listObjData.currentPageProp);
            setRows(listObjData.rowsProp);
            setSortField(listObjData.sortFieldProp);
        } else {
            rolePayload = {
                sortOrder: DEF_SORT_ORDER,
                currentPage: LIST_CRR_PAGE,
                rows: PAGINATOR_ROWS_DEF,
                sortField: ROLES_DEFAULT_SORT_COLUMN,
                case_id: caseID,
            };
            setSortOrder(DEF_SORT_ORDER);
            setCurrentPage(LIST_CRR_PAGE);
            setRows(PAGINATOR_ROWS_DEF);
            setSortField(ROLES_DEFAULT_SORT_COLUMN);
        }
        getNoteList(rolePayload);
        dispatch(companyNotesActions.changeListDataObj(rolePayload));
    }, [caseDetailsData])


    const getNoteList = (payload: any) => {
        dispatch(getNotesListAction(payload))
    };

    /**
     * Handle Sort Action
     *
     * @param {*} data
     */
    const onSort = (order: string, field: string) => {
        let sortOrder = order === REACT_TABLE_ORDER ? DEF_SORT_ORDER : order;
        let sortField = field;
        const rolePayload = {
            sortOrder,
            sortField,
            currentPage,
            rows,
            case_id: caseID,
        }
        setSortOrder(sortOrder);
        setSortField(field);
        getNoteList(rolePayload);
        dispatch(companyNotesActions.changeListDataObj(rolePayload));
    };

    /**
     * Handle Page Change Action
     *
     * @param {*} data
     */
    const handlePageChange = (newPage: number, rows: any) => {
        let currentPage = newPage;
        const rolePayload = {
            sortOrder,
            sortField,
            currentPage,
            rows,
            case_id: caseID,
        }
        setCurrentPage(newPage);
        setRows(rows);
        getNoteList(rolePayload);
        dispatch(companyNotesActions.changeListDataObj(rolePayload));
    };

    const viewNoteInfo = (noteId: any) => {
        setNotesInfo(null);
        const payload = {
            noteId
        }
        dispatch(viewNotesAction(payload)).then((data: any) => {
            if (data.payload.statusCode == 200) {
                setNotesInfo(data.payload.data);
            }
        }).catch((err: any) => {
            console.log(err);
        });
    }


    /**
    * Deleting Roles List
    *
    * @param {*} rowData
    * @returns {*}
    */

    const deleteNotes = (rowData: any) => {
        if (!rowData) return;
        const notes_id = rowData.notes_id;
        const deletePayload = {
            notes_id,
        };

        dispatch(deleteNotesAction(deletePayload)).then((data: any) => {
            const rolePayload = {
                sortOrder,
                sortField,
                currentPage,
                rows,
                case_id: caseID,
            }
            if (data.payload.statusCode == 200) {
                setDeleteShow(false);
                getNoteList(rolePayload);
            }
        }).catch((err: any) => {
            console.log(err);
        });
    };

    const handleViewPopupClick = (rowData: any) => {
        viewNoteInfo(rowData.notes_id);
        setViewNotesModalShow(true)
    }

    // Data Table Start  
    //Action Template
    const actionCellTemplate = (rowData: any) => {
        return (
            <RenderAction
                controlId="documents"
                rowData={rowData}
                isEditPopupEnable={true}
                isDeleteEnable={true}
                handleEditPopupClick={handleEditPopupClick}
                handleDeleteClick={handleDeleteClick}
                isViewPopupEnable={true}
                handleViewPopupClick={handleViewPopupClick}
            />
        );
    };

    // Notes Click Handler
    const handleAddNotesClick = (rowData: any) => {
        setNotesInfo(null)
        setAddNotesModalShow(true);
    }

    const handleCloseAddNotesPopup = (rowData: any) => {
        const rolePayload = {
            sortOrder,
            sortField,
            currentPage,
            rows,
            case_id: caseID
        }
        setAddNotesModalShow(false);
        setNotesInfo(null);
        getNoteList(rolePayload);
    }

    const handleEditPopupClick = (rowData: any) => {
        viewNoteInfo(rowData.notes_id);
        setAddNotesModalShow(true)
    }

    const handleDeleteClick = (rowData: any) => {
        setDeleteShow(true)
        setNotesDelete(rowData);
    };

    // Table Columns
    const columns = [
        {
            field: "normal_notes_content",
            header: "Notes",
        },
        {
            field: "updated_at_without_timezone",
            header: "Last Updated",
            sortable: true,
            width: "140px",
        },
        {
            field: "action",
            header: "Action",
            headerClassName: "theme-action-cell",
            width: "80px",
            body: actionCellTemplate,
        },
    ];

    return (
        <>
            <Loader isLoading={isLoading} classCustom={'theme-loader-z-index'} />
            <div className="px-20">
                <Stack
                    direction="horizontal"
                    gap={2}
                    className="flex-wrap pt-3 pb-2 align-items-start"
                >
                    <h5 className="my-auto fw-semibold me-auto">
                        Notes
                    </h5>
                    <Stack
                        direction="horizontal"
                        gap={2}
                        className="flex-wrap ms-md-auto"
                    >
                        <Button
                            variant="primary"
                            onClick={handleAddNotesClick}
                        >
                            <MdAddCircleOutline size={17} className="me-2" />
                            Add Notes
                        </Button>
                    </Stack>
                </Stack>
                <div className="theme-tabs-table mb-4">
                    <DataGridCommonNew
                        columns={columns}
                        data={notesList}
                        currentPage={currentPage}
                        onPageChange={handlePageChange}
                        onSort={onSort}
                        tableRecords={notesTypeList}
                        rows={rows}
                        sortOrderProp={sortOrder}
                        sortFieldProp={sortField}
                    />
                </div>
            </div>

            {/* Add Notes Modal */}
            <AddNotesModal
                show={addNotesModalShow}
                handleClose={() => handleCloseAddNotesPopup(false)}
                notesData={notesInfo}
            />

            {/* View Notes Modal */}
            <ViewNotesModal
                show={viewNotesModalShow}
                handleClose={() => setViewNotesModalShow(false)}
                notesData={notesInfo}
            />

            {/* Delete Modal */}
            <DeleteModal
                show={deleteShow}
                handleClose={() => setDeleteShow(false)}
                handleDeleteClose={() => setDeleteShow(false)}
                deleteModalHeaderTitle={DELETE_NOTE_TITLE}
                deleteModalBodyContent={DELETE_NOTE_CONTENT}
                deleteRowData={notesDelete}
                customDeleteFunction={deleteNotes}
            />
        </>
    )
}

export default NotesTab